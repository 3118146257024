import packageJson from '../../package.json';

export const environment = {
  production: false,
  appName: 'Heat Exchanger Analysis Tool',
  version: packageJson.version,
  auth: {
    clientId: '007bc79f-07d7-45fa-8016-bc3b910f211b',
    authority: 'https://login.microsoftonline.com/chevron.onmicrosoft.com',
    redirectUri: 'https://heat-dev.azure.chevron.com',
    postLogoutRedirectUri: 'https://heat-dev.azure.chevron.com/auth/logout'
  },
  protectedResourceMap: [
    ['https://heat-api-dev.azure.chevron.com/api', ['https://heatex-test.azure.chevron.com/user_impersonation']],
    [
      'https://heat-function-app.azurewebsites.net/api/HEX_Tool',
      ['https://heatex-test.azure.chevron.com/user_impersonation']
    ]
  ],
  apiEndpoint: 'https://heat-api-dev.azure.chevron.com/api',
  functionAppEndPoint: 'https://heat-function-app.azurewebsites.net/api/HEX_Tool',
  scope: 'https://heatex-test.azure.chevron.com/user_impersonation',
  appInsights: {
    instrumentationKey: 'f8070c25-0712-43af-8f36-3e169209d9f8',
    connectionString:
      'InstrumentationKey=bc322ee2-7e8d-4e52-bfb7-8cc1032e5de2;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/;ApplicationId=8dc21a0b-9dcf-4763-a027-f08cf8ea08eb'
  }
};
