import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router, Event } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ApplicationInsightsService } from '@serv/shared/applicationInsights.service';
import { TitleService } from '@serv/shared/title.service';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  //#region 'Variables'
  public title: string = 'Heat Exchanger Analysis Tool';
  public isIframe: boolean = false;

  private readonly _subscription$: Subscription = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private router: Router,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private msalService: MsalService,
    private appInsightsService: ApplicationInsightsService
  ) {
    const user = this.msalService.instance.getAllAccounts();
    if (user && user.length === 1) {
      this.appInsightsService.setUser(user[0].username);
    }
  }

  ngOnInit(): void {
    this.loadEvents();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private loadEvents(): void {
    this.isIframe = window !== window.parent && !window.opener;

    const isNavigationEnd = (event: Event): boolean => {
      return event instanceof NavigationEnd;
    };

    const getLastChildRoute = (route: ActivatedRoute): ActivatedRoute => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    };

    const isRouteOutletPrimary = (route: ActivatedRoute) => {
      return route.outlet === 'primary';
    };

    this._subscription$.add(
      this.router.events
        .pipe(
          filter(isNavigationEnd),
          map(() => this.activatedRoute),
          map(getLastChildRoute),
          filter(isRouteOutletPrimary),
          mergeMap((route: ActivatedRoute) => route.data)
        )
        .subscribe((data: Data) => {
          const title = data.hasOwnProperty('title') ? data.title : '';
          this.titleService.setTitle(title);
        })
    );
  }
  //#endregion 'Load'
}
