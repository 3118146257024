import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environment';
import { NotificationService } from './notification.service';
import { ApplicationInsightsService } from './applicationInsights.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler {
  //#region 'Angular Life Cycle'
  constructor(
    private _srvNotify: NotificationService,
    private appInsightsService: ApplicationInsightsService
  ) {
    super();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Gets'
  private getClientMessage(error: Error): string {
    console.error(Error);
    //if (environment.production) {
    if (false) {
      return 'An error has occurred';
    } else {
      return error.message ? error.message : error.toString();
    }
  }

  private getClientStack(error: Error): string {
    return error.stack;
  }

  private getServerMessage(error: HttpErrorResponse): string {
    if (!navigator.onLine) {
      this._srvNotify.showError('No Internet Connection');
      return;
    }

    // TODO: Present user-friendly server message to user
    if (environment.production) {
      return ``;
    } else {
      if (error.error.ClassName == 'System.ApplicationException') {
        //if application defined custom error
        //added to see contextualized errors
        return error.error.Message;
      } else {
        return error.message;
      }
    }
  }

  private getServerStack(error: HttpErrorResponse): any {
    // TODO Handle Server Stack
    return error;
  }
  //#endregion 'Gets'

  //#region 'Handlers'
  public handleError(error: Error | HttpErrorResponse): void {
    let message = '';
    let stackTrace = '';

    if (!environment.production) {
      super.handleError(error);
    }

    if (error instanceof HttpErrorResponse) {
      message = this.getServerMessage(error);
      stackTrace = this.getServerStack(error);
      this._srvNotify.showError(`${message}`);
    }

    const internalError = {
      subject: 'Heat Analysis Logs',
      body: error.message,
      date: new Date().toString(),
      name: error.name
    };

    this.appInsightsService.logException(new Error(`Message: ${error.message}`), error, internalError);

    //  else {
    //   message = this.getClientMessage(error);
    //   stackTrace = this.getClientStack(error);
    // }
  }

  //#endregion 'Handlers'
}
